<template>
  <label
    class="ml-6 flex w-full"
    :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
  >
    <input
      :id="`custom_radio_${name}_${value}`"
      v-model="model"
      type="radio"
      class="peer absolute opacity-0"
      :value="value"
      :name="name"
      tabindex="0"
      :disabled="disabled"
    />

    <div
      class="peer-checked:shadow-sm mt-6 h-[20px] w-[20px] rounded-full bg-white ring-1 ring-grey-dark-01 peer-checked:bg-grey-dark-01 peer-checked:ring-offset-white"
      :class="{
        'ring-grey-light-01': disabled,
      }"
      :style="{
        '--tw-ring-offset-width': '5px',
        'box-shadow':
          '0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow)',
      }"
    ></div>

    <p class="pl-16 leading-loose" :class="{ 'text-grey-dark-03': disabled }">
      {{ label }}
    </p>
  </label>
</template>

<script setup lang="ts">
defineProps<{
  label: string
  name: string
  value: string
  disabled?: boolean
}>()

const model = defineModel<string | undefined | null>()
</script>
