<template>
  <Combobox v-if="items.length" v-slot="{ open }" v-model="selected">
    <div class="relative z-20 h-40">
      <div
        class="-z-10 flex h-40 w-full min-w-fit items-start justify-between border-b border-b-grey-mid text-grey-dark-02 focus-within:border-b-grey-dark-01 focus-within:text-black hover:border-b-grey-dark-02 hover:text-black"
      >
        <ComboboxButton
          ref="comboboxButtonRef"
          class="absolute inset-y-0 left-0 flex items-start"
        >
          <SpriteSymbol name="globe" class="h-24 w-28" />
        </ComboboxButton>
        <ComboboxInput
          class="w-full cursor-pointer text-ellipsis px-32 text-base outline-none focus:ring-0"
          :display-value="(item) => (item as DropdownItem).label"
          @change="query = $event.target.value"
          @click="onComboButtonClick($event, open)"
        />
        <ComboboxButton
          ref="comboboxButtonRef"
          class="absolute inset-y-0 right-0 flex items-start"
        >
          <SpriteSymbol name="chevron-down" class="h-24 w-28" />
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="dropdown shadow-md absolute top-[39px] max-h-[360px] w-full min-w-fit overflow-scroll border border-grey-light-01 bg-grey-light-03"
        >
          <div
            v-if="filteredItems.length === 0 && query !== ''"
            class="text-gray-700 relative cursor-default select-none p-16"
          >
            {{ $texts('nothingFound', 'Nothing found.') }}
          </div>
          <!-- eslint-disable vue/no-template-shadow -->
          <ComboboxOption
            v-for="item in filteredItems"
            v-slot="{ selected, active }"
            :key="item.value"
            as="template"
            :value="item"
          >
            <li
              class="z-50 flex h-64 cursor-pointer justify-between px-16 py-16 hover:bg-grey-light-01"
              :class="{
                'bg-grey-light-01': active,
                'bg-grey-light-03': !active,
              }"
            >
              <p>
                {{ item.label }}
              </p>
              <SpriteSymbol
                v-show="selected"
                name="check"
                class="mt-4 h-24 w-28 scale-110"
              />
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'

export type DropdownItem = {
  value: string | number
  label: string
  disabled?: boolean
}

const { $texts } = useEasyTexts()

const props = defineProps<{
  items: DropdownItem[]
}>()

const query = ref('')
const selectedValue = defineModel<string | number | undefined | null>()

const selected = computed<DropdownItem>({
  get(): DropdownItem {
    const match = props.items.find((v) => v.value === selectedValue.value)
    if (match) {
      return match
    }
    return props.items[0]
  },
  set(item: DropdownItem) {
    selectedValue.value = item.value
  },
})

const filteredItems = computed(() =>
  query.value === ''
    ? props.items
    : props.items.filter((item) =>
        item.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, '')),
      ),
)

const comboboxButtonRef = ref()

function onComboButtonClick(
  event: MouseEvent & { target: HTMLInputElement },
  open: boolean,
) {
  event.target.select()
  if (!open) {
    comboboxButtonRef.value.el.click()
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  overflow-y: scroll;
  scrollbar-color: black theme('colors.grey-light-02');
  scrollbar-width: thin;
  opacity: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    opacity: 1;
    width: 4px;
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    background-color: black;
    border-radius: 0;
    width: 1px;
    margin-inline: 100px;
    padding: 20px;
  }

  &::-webkit-scrollbar-track {
    display: block;
    width: 100px;
    background-color: theme('colors.grey-light-02');
    border-radius: 0;
    margin-inline: 20px;
  }
}
</style>
