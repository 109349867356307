<template>
  <fieldset>
    <radio
      v-for="item in options"
      :key="item.value"
      v-model="model"
      :value="item.value"
      :label="item.label"
      :disabled="item.disabled"
      :name="name"
      class="mb-16"
    />
  </fieldset>
</template>

<script setup lang="ts">
import Radio from './Radio.vue'

export type RadioGroupOption = {
  label: string
  disabled?: boolean
  value: string
}

defineProps<{
  name: string
  options: RadioGroupOption[]
}>()

const model = defineModel<string | undefined | null>()
</script>
